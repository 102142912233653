import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { HeadingLarge, HeadingMedium, Prefix, Link } from '@ycos/primitives'
import { Picture } from '@ycos/picture'
import { createComponent } from '@ycos/fela'
import { getTags, hasTag } from '@ycos/coremedia-renderer'
import Markdown from '@ycos/markdown'
import url from 'url'
import StandardVideo from './StandardVideo'
import { AmbientVideo } from '@ycos/component-video'
import Icon from '../component-icon'
import VisibilityWrapper from '../component-visibility'

// Parent Containers
const MediaContainer = createComponent(
  'MediaContainer',
  ({ theme, isFullWidth, isFullBleed }) => {
    let padding = 4 * theme.spacingMultiplier

    if (isFullBleed || isFullWidth) {
      padding = 0
    }

    return {
      marginBottom: `${2.5 * theme.spacingMultiplier}px`,
      width: isFullBleed ? 'calc(100vw - ((100vw - 100%)/2))' : 'auto',
      position: 'relative',
      transform: isFullBleed ? 'translate(-50%, 0)' : 'initial',
      left: isFullBleed ? '50%' : 'initial',
      maxWidth: isFullBleed ? `${theme.breakpoints.large.width}px` : 'initial',
      'screen-medium': {
        paddingRight: `${padding}px`,
        paddingLeft: `${padding}px`
      }
    }
  },
  'div'
)

const Container = createComponent(
  'Container',
  ({ theme, isAlignedLeft, isFullWidth }) => {
    const widths = {
      large: theme.breakpoints.large.width,
      medium: (theme.breakpoints.large.width / 12) * 8
    }

    return {
      textAlign: isAlignedLeft ? 'left' : 'center',
      position: 'relative',
      maxWidth: `${widths.width}px`,
      width: '100%',
      padding: isFullWidth ? '0' : `0 ${4 * theme.spacingMultiplier}px`,
      margin: '0 auto',
      'screen-medium': {
        maxWidth: isFullWidth ? `${widths.width}px` : `${widths.medium}px`,
        padding: '0'
      },
      'screen-large': {
        maxWidth: isFullWidth ? `${widths.width}px` : `${widths.medium}px`
      }
    }
  },
  'div'
)

// Element Containers
const TitleContainer = createComponent(
  'TitleContainer',
  ({ theme }) => ({
    marginBottom: `${1 * theme.spacingMultiplier}px`
  }),
  'div'
)

const MarkdownContainer = createComponent(
  'MarkdownContainer',
  ({ theme }) => ({
    marginBottom: `-${0.75 * theme.spacingMultiplier}px`
  }),
  'div'
)

const LinkContainer = createComponent(
  'LinkContainer',
  ({ theme }) => ({
    marginBottom: `${theme.spacingMultiplier}px`
  }),
  'div'
)

const PrefixContainer = createComponent(
  'PrefixContainer',
  ({ theme }) => ({
    marginBottom: `-${theme.spacingMultiplier}px`
  }),
  'div'
)

const HeadingTitleContainer = createComponent(
  'HeadingTitleContainer',
  ({ theme }) => ({
    marginBottom: `${2.5 * theme.spacingMultiplier}px`
  }),
  'div'
)

const HeadingMarkdownContainer = createComponent(
  'HeadingMarkdownContainer',
  ({ theme }) => ({
    marginBottom: `-${0.5 * theme.spacingMultiplier}px`
  }),
  'div'
)

// Homepage
const HomepageHeading = createComponent(
  'HomepageHeading',
  ({ theme, inverseColor }) => ({
    ...theme.homepage.heading,
    color: inverseColor ? theme.typography.colors.PrimaryWhite : theme.typography.colors.PrimaryBlack
  }),
  'h1'
)

const HomepageHeadingMedium = createComponent(
  'HomepageHeadingMedium',
  ({ theme, inverseColor }) => {
    return {
      ...theme.typography.styles.Heading.B3.bp3,
      color: inverseColor ? theme.typography.colors.PrimaryWhite : theme.typography.colors.PrimaryBlack,
      textTransform: 'none'
    }
  },
  'h2'
)

const HomepagePretitle = createComponent(
  'HomepagePretitle',
  ({ theme, inverseColor }) => ({
    ...theme.homepage.preTitle,
    color: inverseColor ? theme.typography.colors.PrimaryWhite : theme.typography.colors.PrimaryBlack
  }),
  'p'
)

const HomePageSubtitle = createComponent('HomepageSubtitle', ({ theme, inverseColor }) => ({
  color: inverseColor ? theme.typography.colors.PrimaryWhite : theme.typography.colors.PrimaryBlack,
  marginBottom: `${3 * theme.spacingMultiplier}px`
}))

const HomePageButton = createComponent(
  'HomePageButton',
  ({ theme }) => ({
    ...theme.homepage.cta,
    ...theme.homepage.ctaHover,
    'screen-small-max': {
      width: '100%',
      marginLeft: 0,
      marginRight: 0
    }
  }),
  'p',
  ['data-extra-text']
)

export const getLinkDetails = (targets = []) => {
  return targets.map(({ target, callToActionEnabled, callToActionCustomText, parameter = '' }) => {
    const href = `${target.categoryLink || target.pageURL || target.href || ''}${parameter}`
    let extractedSeoSegment
    if (target && target.type === 'augmentedCategory') {
      extractedSeoSegment =
        typeof target.categoryLink === 'string'
          ? `/${ 
            target.categoryLink
              .split('/')
              .slice(3)
              .join('/')}`
          : ''
    }
    if (target && target.type === 'page') {
      extractedSeoSegment = typeof target.key === 'string' ? target.key : ''
    }
    const parsedUrl = url.parse(href)
    const useSameTab = href.startsWith('mailto') || !parsedUrl.host || parsedUrl.host.includes('theoutnet')

    return {
      href,
      seoSegment: extractedSeoSegment,
      callToActionEnabled,
      callToActionCustomText,
      parameter,
      targetType: useSameTab ? '_self' : '_blank'
    }
  })
}

const WrappedLinkHOC = ({ targets, onTargetClick }) => ({ children, ...rest }) => {
  const links = getLinkDetails(targets)
  if (links.length > 0) {
    const link = links[0]

    return (
      <>
        <Link data-seo-segment={link.seoSegment} href={link.href} onClick={onTargetClick} target={link.targetType} {...rest}>
          {children}
        </Link>
      </>
    )
  }
  return children
}

export const Video = ({
  width,
  height,
  soundPlaying,
  controlsVisible,
  loop,
  tags,
  config,
  pictures = [],
  brightCoveDataUrl,
  ynapParameter,
  dataURL,
  ...rest
}) => {
  const aspectRatio = width && height && `${width}:${height}`
  const { account, player } = config && config.brightcove
  const videoId = brightCoveDataUrl || ynapParameter || dataURL
  const playerID = soundPlaying ? player.regular : player.soundless

  return (
    <Fragment>
      <style text="text/css">{`.vjs-errors-dialog{display:none;}`}</style>
      {controlsVisible ? (
        <StandardVideo
          aspectRatio={aspectRatio}
          videoId={videoId}
          item={rest}
          config={config}
          controls={controlsVisible}
          loop={loop}
          soundless={!soundPlaying}
        />
      ) : (
        <AmbientVideo account={account} playerId={playerID} videoId={videoId} pictures={pictures} lazy={false} loop={loop} />
      )}
    </Fragment>
  )
}

// If there is a video and there is a target, we wrap the Video with the target so its clickable
const getVideoComponent = ({ targets, video, config, videoControlsVisible, videoLoop, videoMuted, WrappedLink }) => {
  return targets.length === 1 ? (
    <WrappedLink data-automation="media-video-link">
      <Video {...video} config={config} controlsVisible={videoControlsVisible} loop={videoLoop} soundPlaying={videoMuted} />
    </WrappedLink>
  ) : (
    <Video {...video} config={config} controlsVisible={videoControlsVisible} loop={videoLoop} soundPlaying={videoMuted} />
  )
}

const Block = (props) => {
  const { onTargetClick, config, onMarkdownTargetClick, page, locale } = props
  const {
    teaserText,
    preTitlePlain,
    teaserTitle,
    subTitlePlain,
    pictures = [],
    videos = [],
    targets = [],
    tags = '',
    anchorpoint,
    ynapParameter,
    contentID
  } = props.item
  const iconTags = getTags(tags, 'Icon')
  const isAlignedLeft = hasTag(tags, 'Align.Left')
  const isFullWidth = hasTag(tags, 'Content.FullWidth')
  const isFullBleed = hasTag(tags, 'Image.FullBleed')
  const isHomepage = page && (page.pageKey === 'homepage' || page.pageKey === 'homepage-mens')
  const isHomepageBlock = hasTag(tags, 'Content.PageType.Homepage')
  const isHomepageHero = hasTag(tags, 'Content.TopSpacing')
  const isTitleBlock = hasTag(tags, 'Content.TitleBlock')
  const videoControlsVisible = hasTag(tags, 'Content.VideoControlsVisible')
  const videoMuted = hasTag(tags, 'Content.VideoSoundOff')
  const videoLoop = hasTag(tags, 'Content.VideoLoop')
  const isColorInverse = hasTag(tags, 'Product.InverseColor')
  const hasTags = !!iconTags.length
  const hasVideo = videos.length > 0
  const hasMedia = !!(hasVideo || pictures.length)
  const hasContent = !!(teaserText || hasTag || preTitlePlain || teaserTitle || subTitlePlain)
  const video = videos[0]
  const WrappedLink = WrappedLinkHOC({ targets, onTargetClick, ynapParameter })
  const isDeviceSpecific = getTags(tags, 'DeviceType')

  return (
    <VisibilityWrapper isDeviceSpecific={isDeviceSpecific} data-automation="block-component" data-content-id={contentID}>
      {hasMedia && (
        <MediaContainer data-automation="media-container" isFullWidth={isFullWidth} isFullBleed={isFullBleed}>
          {hasVideo
            ? getVideoComponent({ targets, video, config, videoControlsVisible, videoLoop, videoMuted, WrappedLink })
            : (targets.length === 1 && (
            <WrappedLink data-automation="media-picture-link">
              <Picture images={pictures} imagesphere={config.imageSphere} />
            </WrappedLink>
              )) ||
              (targets.length !== 1 && (
                <Fragment>
                  <Picture images={pictures} imagesphere={config.imageSphere} />
                </Fragment>
              ))}
        </MediaContainer>
      )}
      {hasContent && (
        <Container isAlignedLeft={isAlignedLeft} isFullWidth={isFullWidth}>
          <Fragment>
            {hasTags && <Icon type={iconTags[0]} />}
            {preTitlePlain && (
              <PrefixContainer>
                {isHomepageHero ? (
                  <HomepagePretitle inverseColor={isColorInverse}>{preTitlePlain}</HomepagePretitle>
                ) : (
                  <Prefix inverseColor={isColorInverse}>{preTitlePlain}</Prefix>
                )}
              </PrefixContainer>
            )}
            {teaserTitle && (
              <WrappedLink>
                {isHomepageHero && (
                  <TitleContainer>
                    <HomepageHeading inverseColor={isColorInverse}>{teaserTitle}</HomepageHeading>
                  </TitleContainer>
                )}
                {isTitleBlock && (
                  <HeadingTitleContainer>
                    <HeadingLarge inverseColor={isColorInverse}>{teaserTitle}</HeadingLarge>
                  </HeadingTitleContainer>
                )}
                {!isHomepageHero && !isTitleBlock && isHomepage && (
                  <TitleContainer>
                    <HomepageHeadingMedium language={locale.language} inverseColor={isColorInverse}>
                      {teaserTitle}
                    </HomepageHeadingMedium>
                  </TitleContainer>
                )}
                {!isHomepageHero && !isTitleBlock && !isHomepage && (
                  <TitleContainer>
                    <HeadingMedium inverseColor={isColorInverse}>{teaserTitle}</HeadingMedium>
                  </TitleContainer>
                )}
              </WrappedLink>
            )}
            {teaserText && (
              <WrappedLink>
                {isTitleBlock ? (
                  <HeadingMarkdownContainer>
                    <Markdown inverseColor={isColorInverse} anchorpoint={anchorpoint} teaserText={teaserText} onMarkdownTargetClick={onMarkdownTargetClick} />
                  </HeadingMarkdownContainer>
                ) : (
                  <MarkdownContainer>
                    <Markdown inverseColor={isColorInverse} anchorpoint={anchorpoint} teaserText={teaserText} onMarkdownTargetClick={onMarkdownTargetClick} />
                  </MarkdownContainer>
                )}
              </WrappedLink>
            )}
          </Fragment>

          {targets.length > 0 &&
            targets.map((target, key) => (
              <LinkContainer key={key}>
                {isHomepageHero && <HomePageSubtitle inverseColor={isColorInverse}>{target.anchorPointName || subTitlePlain}</HomePageSubtitle>}
                {isHomepageBlock && (
                  <WrappedLink data-automation="secondary-cta">
                    <HomePageButton data-extra-text={target.callToActionCustomText || anchorpoint}>{target.anchorPointName || subTitlePlain}</HomePageButton>
                  </WrappedLink>
                )}
                {!isHomepageHero && !isHomepageBlock && (
                  <WrappedLink data-automation="tertiary-cta" inverseColor={isColorInverse}>
                    {target.anchorPointName || subTitlePlain}
                  </WrappedLink>
                )}
              </LinkContainer>
            ))}
          {targets.length === 0 && subTitlePlain && (
            <LinkContainer>
              {isHomepageHero && <HomePageSubtitle inverseColor={isColorInverse}>{subTitlePlain}</HomePageSubtitle>}
              {isHomepageBlock && (
                <WrappedLink data-automation="secondary-cta">
                  <HomePageButton data-extra-text={anchorpoint}>{subTitlePlain}</HomePageButton>
                </WrappedLink>
              )}
              {!isHomepageHero && !isHomepageBlock && (
                <WrappedLink data-automation="tertiary-cta" inverseColor={isColorInverse}>
                  {subTitlePlain}
                </WrappedLink>
              )}
            </LinkContainer>
          )}
        </Container>
      )}
    </VisibilityWrapper>
  )
}

Block.layoutVariant = ['osc-block']

Block.propTypes = {
  onMarkdownTargetClick: PropTypes.func,
  item: PropTypes.shape({
    anchorpoint: PropTypes.string,
    title: PropTypes.string,
    layoutVariant: PropTypes.string,
    teaserText: PropTypes.string,
    teaserTitle: PropTypes.string,
    additionalCTA: PropTypes.array,
    pictures: PropTypes.array,
    videos: PropTypes.array,
    preTitlePlain: PropTypes.string,
    subTitlePlain: PropTypes.string,
    target: PropTypes.shape({
      href: PropTypes.string,
      teaserTitle: PropTypes.teaserTitle
    })
  }),
  config: PropTypes.shape({
    imagesphere: PropTypes.shape({
      quality: PropTypes.number
    }),
    brightcove: PropTypes.shape({
      account: PropTypes.number,
      player: PropTypes.shape({
        regular: PropTypes.string,
        soundless: PropTypes.string
      })
    })
  })
}

Block.defaultProps = {
  item: {},
  config: {}
}

export default Block
